import React from 'react'
import { Link } from 'gatsby'
import styled from "styled-components"
import { StaticImage } from 'gatsby-plugin-image'
import { deviceMax } from "../../styles/media"
import "@fontsource/nerko-one"


const Container = styled.div`
    /* position: sticky;
    top: 84vh;
    left: 0; */
    width: 15rem;
    height: 5rem;
    /* margin-top: 10rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 2; */
    /* padding-top: 10rem; */
    /* margin-left: 1rem;
    margin-bottom: 2rem; */
    /* margin-top: 10rem; */
    /* height: 6rem; */
    /* display: flex; */
    /* justify-content: space-between; */
    align-items: center;
    /* border: 2px solid red; */
    text-decoration: none;
    color: #FFF;
    filter: drop-shadow(0px 10px 30px rgba(0,0,0,1));
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    transition: .5s;
    &:hover {
        transition: .1s;
        transform: scale(1.02);
    }

`;
const LinkWrap = styled(props => <Link {...props} />)`
    text-decoration: none;
    color: #FFF;
`;
const BoxWrap = styled.div`
    position: relative;
    width: 15rem;
    height: 5rem;
    margin: 3rem 0;
    /* overflow-x: hidden; */
    /* border: 2px solid orange; */
`;
const Circle = styled.div`
    position: absolute;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #0F132E;
    border: 1px solid #4E7187;
    z-index: 3;

    @media ${deviceMax.laptop} { 
        transform: translate(-.5rem, .5rem) scale(.8);        
    }
    @media ${deviceMax.mobileL} { 
        transform: translate(-.5rem, .5rem) scale(.8);        
    }
    

    ${Container}:hover & {
        transition: .1s ease-in;
        border-color: #b9d2e2;
        box-shadow: 0px 1px 5px #000;           
    }
`;
// const ImageWrapper = styled.div`
//     position: relative;
//     top: 50%;
//     left: 50%;
//     transform-origin: center;
//     transform: translate(-17.5px,-35.5px);
// `;
const TabWrap = styled.div`
    position: relative;
    width: 10rem;
    height: 2.4rem;
    margin-top: .8rem;
    margin-left: 2rem;
    padding-left: .8rem;
    overflow-x: hidden;
    /* border: 2px solid blue; */
    
`;
const Rectangle = styled.div`
    /* position: absolute;
    top: 0; */
    width: 9.2rem;
    height: 2.4rem;
    /* margin-top: 1rem;
    margin-left: 2.5rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: .8rem;
    background-color: #0F132E;
    border: 1px solid #4E7187;
    font-family: "Nerko One";
    font-size: 1.5rem;
    z-index: 2;
    opacity: ${props => props.theme.opacity};
    transition: .4s ease-out;
    transform: ${props => props.theme.transform};
    
    @media ${deviceMax.laptop} { 
        display: none;        
    }
    @media ${deviceMax.mobileL} { 
        display: none;        
    }

    ${Container}:hover & {
        transition: .2s ease-in;
        border-color: #b9d2e2;
        box-shadow: 0px 1px 5px #000;
        opacity: 1;
        transform: translateX(0rem);           
    }
`;

Rectangle.defaultProps = {
    theme: {
      opacity: "0",
      transform: "translateX(-11.5rem)"
    }
  }

export const show = {
    opacity: "1",
    transform: "translateX(0rem)"
  };

export function HelpButton() {


    return (    
        <Container>
            <BoxWrap>
                <LinkWrap to={`/resources`}>
                    <Circle>
                        <StaticImage
                            src="../../images/thermo/thermo.png"
                            alt="Line Thermometer"
                            layout="constrained"
                            placeholder="none"
                            height={56}
                        />
                    </Circle>
                    <TabWrap>
                        <Rectangle>NEED HELP?</Rectangle>
                    </TabWrap>
                </LinkWrap>
            </BoxWrap>
        </Container>
    )
}
