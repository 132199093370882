import React from "react"
import { graphql, Link, useStaticQuery } from 'gatsby' 
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import styled, { css, ThemeProvider } from "styled-components"
import scrollTo from 'gatsby-plugin-smoothscroll';
import { palette } from "../styles/variables.js"

import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { useWindowSize } from "../components/general/window";

import 'react-responsive-modal/styles.css';
import { PageContainer, ContentWrapper, Line, BumperOffset } from '../components/general/general'
import { HelpButton, show } from "../components/general/helpButton.js"
import banner from "../images/index/banner.jpg"
import black_paper from "../images/textures/black-paper.png"

import Layout from '../components/layout/layout'
import Head from '../components/general/head'
import * as characterPageStyles from "../styles/pages/characterPage.module.scss"
import { deviceMin, deviceMax } from "../styles/media"


import "@fontsource/bebas-neue"
import "@fontsource/raleway/700.css"
import "@fontsource/raleway/400.css"
import "@fontsource/alegreya-sans/500.css"
import "@fontsource/alegreya-sans/400.css"
import "@fontsource/alegreya-sans/300.css"




// Styled Components

const ContentWrapperAlt = styled(ContentWrapper)`
    margin-top: -6rem;
    padding-top: 6rem;
`;

const Characters = styled.div`
    position: relative;
    z-index: 3;
    width: 100%;
    height: auto;
    background-image: url(${banner});
    background-size: cover;  
`;

const Overlay = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 15, 41, 0.7);
`;

const Windows = styled.div`
    width: 100%;
    padding: 2.5rem;
    padding-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    @media ${deviceMax.laptop} { 
        /* padding: 1rem; */
    }
    @media ${deviceMax.mobileL} { 
        /* padding: 1rem; */
    }
`;
const WindowWrap = styled.div`
    /* border: 2px solid purple; */
    /* width: 16.66%; */
    /* margin: 0 1rem; */
    /* flex-grow: 1; */
    display: flex;
    justify-content: center;
    align-self: center;
    flex-basis: 16.66%;
    @media ${deviceMax.laptopL} {
        flex-basis: 33.33%;
        margin-bottom: 2rem;
    }
    @media ${deviceMax.mobileL} {
        /* flex-basis: 50%; */
        margin-bottom: 1rem;
    }
`;
const Window = styled.div`
    width: 10rem;
    height: 28rem;
    /* padding-top: 1rem; */

    border: 3px solid rgba(255,255,255,0.8);
    border-radius: 3px;

    transform: skewX(-10deg);
    background: ${props => props.background};
    object-fit: contain;
    overflow: hidden;
    cursor: pointer;
    transition: .4s ;

    &:hover {
        transition: .2s ease-in-out;
        // margin: 0 2rem;
        transform: skewX(-10deg);
        width: 12rem;
    }
    @media ${deviceMax.laptopL} {
        /* max-width: 10rem;
        flex-basis: 25%; */
        /* margin: 0 .5rem 2rem; */
    }
    @media ${deviceMax.mobileL} { 
        /* flex-basis: 44.5%; */
        /* margin: 0 .5rem 1rem .5rem; */
        display: flex;
        justify-content: center;
        width: 8rem;
        height: 20rem;
        transform: skewX(0deg);
        &:hover {
            transform: skewX(0deg);
            width: 8rem;
        }
        
    }
`;
const NameUnderWrap = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 3;
    /* margin-left: -1rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
    opacity: 1;
    transition: .2s;
    /* ${Window}:hover & {
        opacity: 1;
    } */
`;
const NameUnder = styled.h2`
    font-family: "Bebas Neue";
    font-size: 2.5rem;
    margin: 0;
    color: ${props => props.color};
    transform: skewX(10deg);
    @media ${deviceMax.mobileL} { 
        font-size: 1.8rem;
        transform: skewX(0deg);        
    }
    
`;
const ImageWrapper = styled.div`
    position: relative;
    top: 0;
    height: 100%;
    width: 15rem;


    // display: flex;
    // justify-content: center;
    // align-items: center;
    transform: skewX(10deg);
    /* border: 2px solid red; */
    @media ${deviceMax.mobileL} { 
        width: 10rem;
        transform: skewX(0deg);
        
    }
`;

const BlackWhite = styled(props => <GatsbyImage {...props} />)`
    position: relative;
    top: 0;
    z-index: 1;
    height: 100%;
    max-height: 498px;
    margin-top: 1rem;
    transition: .2s;
    ${Window}:hover & {
        filter: blur(10px);
    }
`;

const Color = styled(props => <GatsbyImage {...props} />)`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    max-height: 498px;
    margin-top: 1rem;
    opacity: 0;
    transition: .2s;
    ${Window}:hover & {
        opacity: 1;
    }
//    border: 2px solid pink;
`;

const Ribbon = styled.div`
    width: 100%;
    // height: 15rem;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${palette.avengerDarkPurple};
    filter: drop-shadow(0px 30px 15px rgba(0,0,0,0.2));
`;

const Title = styled.h1`
    font-family: "Bebas Neue";
    font-size: 7rem;
    color: $title-white;
    letter-spacing: .2rem;
    margin: 0;
    @media ${deviceMax.mobileL} { 
        font-size: 3rem;
    }
`;

const Subtext = styled.p`
    margin-top: 1rem;
    text-transform: uppercase;
    text-align: center;
    font-family: "Raleway";
    @media ${deviceMax.mobileL} { 
        font-size: .8rem;
        padding: 0 1rem;
    }
`;

const DividerContainer = styled.div`
    position: relative;
    height: 15rem;
    z-index: 2;
`;

const Panels = styled.div`
    position: relative;
    margin-top: -15rem;
`;


const Panel = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;


    background: ${props => props.background};
    @media ${deviceMax.mobileL} {
        
        /* height: 100vh; */
        /* min-height: 100vh; */
    }

    /* background-color: ${props => props.theme.main}; */

    /* ${props => props.samantha && css`
        background-color: red;
    `} */
`;

const Texture = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${black_paper});

`;
const Card = styled.div`
    position: relative;
    height: 30.1rem;
    width: 42rem;
    z-index: 1;
    border: 2px solid ${props => props.border};
    border-radius: .5rem;

    box-shadow: 0px 35px 50px ${palette.blackAlpha60};
    background: ${props => props.background};
    transition: 1s;
    transition-delay: .1s;
    margin: 2rem 0;
    &:hover {
        transition: .5s;
        transform: scale(1.01);
        
    }
    
    @media ${deviceMax.tablet} { 
        width: auto;
        height: auto;
        margin: 2rem 1rem;
        box-shadow: 0px 0px 0px ${palette.blackAlpha60};
        /* display: none; */
    }
`;

const Left = styled.div`
    height: 100%;
    width: 70%;
    // border: 2px solid magenta;
    margin-right: 1rem;
    @media ${deviceMax.tablet} { 
        width: 100%;
        /* z-index: 3; */
    }
`;

const Right = styled.div`
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: center;
    // border: 2px solid blue;
    @media ${deviceMax.tablet} { 
        width: 100%;
        /* height: 2rem; */
        opacity:
    }
`;

const ThumbnailWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    
    
    @media ${deviceMax.tablet} { 
        /* width: 70%; */
        height: 8rem;
        margin-bottom: 1rem;
        /* border: 2px solid yellow; */
        /* justify-content: flex-end; */
        /* margin-right: -2rem; */
        overflow: hidden;
        border-bottom: 2px solid ${props => props.color};
        /* overflow-y: visible; */
        
    }
`;

const Thumbnail = styled(props => <GatsbyImage {...props} />)`
    height: 100%;
    width: 100%;
    /* border: 2px solid green; */
    @media ${deviceMax.mobileL} { 
        /* min-width: 20rem; */
        /* border: 2px solid orange; */
        /* margin-right: -2rem; */
        /* max-height: 2rem; */
        /* opacity: .5; */
    }
`;

const Up = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    padding: 1rem;
    @media ${deviceMax.tablet} { 
        flex-direction: column-reverse;
    }
    
`;
const Down = styled.div`
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 .5rem .5rem;
    border-top: 2px solid ${props => props.border};
    background: ${props => props.background};
    @media ${deviceMax.tablet} { 
        height: 5rem;
    }
`;
const Name = styled.h2`
    font-family: "Bebas Neue";
    font-size: 4rem;
    color: ${props => props.color};
    margin: 0;
`;
const Home = styled.h3`
    font-family: "Alegreya Sans";
    font-size: 1.1rem;
    font-weight: 600;
    font-style: italic;
    color: ${props => props.color};
    margin-bottom: .5rem;
`;
const Story = styled.p`
    font-family: "Alegreya Sans";
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.3rem;
    text-align: justify;
    text-justify: inter-word;
    color: ${props => props.color};
    margin-bottom: .5rem;
`;
const Read = styled.p`
    font-family: "Alegreya Sans";
    font-size: .9rem;
    font-weight: 500;
    font-style: italic;
    color: ${props => props.color};
    margin-top: .5rem;
`;
const Button = styled(props => <Link {...props} />)`
    text-decoration: none;
    margin: 0 1rem;
    z-index: 10;
    color: #FFF;
    background-color: #0F132E;
    border: 1px solid #4E7187;
    border-radius: .2rem;
    font-family: "Raleway";
    font-size: 1.2rem;
    font-weight: 700;
    // font-size: 2rem;
    padding: .7rem 1.1rem;

    cursor: pointer;
    transition: .2s ease-out;
    
    /* ${Card}:hover & {
            background: red;
    } */

    &:hover {
        transition: .1s ease-in;
        /* color: #FFF; */
        border-color: #b9d2e2;
        box-shadow: 0px 1px 5px #000;            
    }
`;

const Bumper = styled.div`
    position: sticky;
    top: 88vh;
    /* border: 2px solid blue;  */
    height: 5rem;
    width:15rem;
    margin: 10rem 0 2rem 1rem;
    @media ${deviceMax.laptop} { 
        z-index: 10;
        /* transform: scale(.5); */
    }
    @media ${deviceMax.mobileL} { 
        z-index: 10;
        /* transform: scale(.5); */
    }
`;
// const HelpTool = styled(HelpButton)`
//     &&& {
//         margin-top: 10rem;
//         border: 2px solid blue;
//         transform: rotate(45deg);
//         width: 0px;
//     }
// `;
const CharactersPage = () => {

    const data = useStaticQuery( graphql`
    query {
        allContentfulCharacter(
            sort: {
                    fields: orderId,
                  order: ASC
          }){
              nodes {
                    slug
                    characterName
                    characterColors {
                        primary
                        desaturated
                        highlight
                        lowlight
                    } 
                    characterAssets {
                        illustratedColor {
                            gatsbyImageData (
                                layout: CONSTRAINED
                                height: 498
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                            description
                        }
                        illustratedBlackWhite {
                            gatsbyImageData (
                                layout: CONSTRAINED
                                height: 498
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                            description
                        }
                    }
                    characterCard {
                        characterName
                        hometown
                        description {
                            description
                        }
                        thumbnail {
                            illustratedColor {
                                gatsbyImageData (
                                    layout: FIXED
                                    width: 210
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP]
                                    cropFocus: TOP


                                )
                                description
                            }
                        }
                    }
                }    
            }
        }
    `);
    
    let window = useWindowSize()
    const width  = window[0]
    const height  = window[1]
    console.log(width)
    console.log(height)



    //   disableParallax(windowWidth) {
	// 	return windowWidth <= 480;
	// }
    

    return (
        <Layout>
            <Head title="The Characters"/>
            <PageContainer>
                <ContentWrapperAlt>
                    <Characters>
                        <Overlay>
                            <Windows>
                                {data.allContentfulCharacter.nodes.map((node) => {
                                    return (
                                        <WindowWrap>
                                            <Window onClick={() => scrollTo(`#${node.slug}`)} background={node.characterColors.primary}>
                                                <NameUnderWrap>
                                                    <NameUnder color={node.characterColors.highlight}>{node.characterName}</NameUnder>
                                                </NameUnderWrap>
                                                <ImageWrapper>
                                                    <BlackWhite
                                                            image={node.characterAssets.illustratedBlackWhite.gatsbyImageData}
                                                            alt={node.characterAssets.illustratedBlackWhite.description}
                                                        />
                                                    <Color
                                                        image={node.characterAssets.illustratedColor.gatsbyImageData}
                                                        alt={node.characterAssets.illustratedColor.description}
                                                    />
                                                </ImageWrapper>
                                            </Window>
                                        </WindowWrap>
                                    )
                                })}
                            </Windows>
                            <Ribbon>
                                <Title>THE CHARACTERS</Title>
                                <Line>
                                    <StaticImage
                                        src="../images/lines/medium-line.png"
                                        alt="Medium Weight Line"
                                        layout="constrained"
                                    />
                                </Line>
                                <Subtext>Learn more about the Trauma Avengers and their mental health challenges</Subtext>
                                {/* <MyComponent /> */}
                            </Ribbon>
                        </Overlay>    
                    </Characters>
                    <DividerContainer>
                        <svg className={characterPageStyles.dividerShape1} 
                             xmlns="http://www.w3.org/2000/svg" 
                             viewBox="0 0 100 125" preserveAspectRatio="none">
                            <defs>
                                <filter id="slash1" x="0" y="0" width="100%" height="200%">
                                    <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
                                    <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
                                    <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                                </filter>
                                <filter id="slash2" x="0" y="0" width="100%" height="200%">
                                    <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
                                    <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
                                    <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                                </filter>
                            </defs>
                            <path className={characterPageStyles.slash1} d="M100 0L0 0 0 60 100 5z" ></path>
                            <path className={characterPageStyles.slash2} d="M100 0L0 0 0 30 100 5z" ></path>
                        </svg>                           
                    </DividerContainer>
                    <Panels>
                        <ThemeProvider theme={show}>
                            <Bumper>
                                <HelpButton />
                            </Bumper>
                        </ThemeProvider>

                        <BumperOffset>
                            {data.allContentfulCharacter.nodes.map((node) => {
                                return (
                                    <Panel id={node.slug} background={node.characterColors.lowlight}>
                                        <ParallaxProvider>
                                        <Texture>
                                        <Parallax
                                        // className="custom-class"
                                        y={[-20, 20]}
                                        // offsetYMax={10}
                                        // offsetYMin={-120}
                                        slowerScrollRate
                                        disabled={width <= 1024 || height <= 800}
                                        // tag="figure"
                                        >
                                            <Card background={node.characterColors.primary} border={node.characterColors.highlight}>
                                                <Up>
                                                    <Left>
                                                        <Name color={node.characterColors.highlight}>{node.characterName}</Name>
                                                        <Home color={node.characterColors.highlight}>{node.characterCard.hometown}</Home>
                                                        <Story color={node.characterColors.highlight}>{node.characterCard.description.description}</Story>
                                                        <Read color={node.characterColors.highlight}>Click below and learn more...</Read>
                                                    </Left>
                                                    <Right>
                                                        <ThumbnailWrapper color={node.characterColors.highlight}>
                                                            <Thumbnail
                                                                image={node.characterCard.thumbnail.illustratedColor.gatsbyImageData}
                                                                alt={node.characterCard.thumbnail.illustratedColor.description}
                                                            />
                                                        </ThumbnailWrapper>
                                                    </Right>
                                                </Up>
                                                <Down background={node.characterColors.desaturated} border={node.characterColors.highlight}>
                                                    <Button to={`/characters/${node.slug}`}>FULL STORY</Button>
                                                </Down>
                                            </Card>
                                            </Parallax>
                                        </Texture>
                                        </ParallaxProvider>
                                    </Panel>
                                )
                            })}
                        </BumperOffset>
                    </Panels>
                </ContentWrapperAlt>
            </PageContainer>
        </Layout>
    )
}



export default CharactersPage

